import Cookies from './Cookies';
import ApiError from './ApiError';
import Axios from 'axios';

const APP_ID = process.env.VUE_APP_ID;
const PROTOCOL = process.env.VUE_APP_API_PROTOCOL || window.location.protocol.replace(':', '');
const API_DOMAINS = (process.env.VUE_APP_API_DOMAINS || '').split(',').map((x: any) => x.trim());

const STATUS_SUCCESS = 'success';
const STATUS_FAILURE = 'failure';
const STATUS_COREFAULT = 'corefault';

const sessionApiDomain = Cookies.get(`${APP_ID}.sessionApiDomain`);

export const API_DOMAIN = sessionApiDomain && API_DOMAINS.includes(sessionApiDomain) ? sessionApiDomain : API_DOMAINS[0];
export const BASE_URL = `${PROTOCOL}://${API_DOMAIN}`;

const axios = Axios.create({
  // crossDomain: true,
  responseType: 'json',
  baseURL: BASE_URL,
});

////////////////////////////////////////////////////////////////////////////////////////////////

export default class API {
  public static INVALID_TOKEN = 'InvalidToken';
  public static baseUrl = BASE_URL;

  public static async get(path: string, params?: { [key: string]: any }, handleInvalidToken = true) {
    const cleanedPath = path[0] === '/' ? path : `/${path}`;
    const options = params ? { params } : {};
    const res = await axios.get(cleanedPath, injectAuthorizationHeader(options));
    if (res.data.status === STATUS_SUCCESS) {
      return res.data.data;
    }
    handleApiError(res.data.data || res.data, cleanedPath, handleInvalidToken);
    return new Promise(() => false);
  }

  public static async post(path: string, params?: { [key: string]: any }, handleInvalidToken = true) {
    const cleanedPath = path[0] === '/' ? path : `/${path}`;
    const res = await axios.post(cleanedPath, params || {}, injectAuthorizationHeader({}));
    if (res.data.status === STATUS_SUCCESS) {
      return res.data.data;
    }
    handleApiError(res.data.data || res.data, cleanedPath, handleInvalidToken);
    return new Promise(() => false);
  }

  public static async postFormData(path: string, formData: FormData, handleInvalidToken = true) {
    const cleanedPath = path[0] === '/' ? path : `/${path}`;
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    const res = await axios.post(cleanedPath, formData, injectAuthorizationHeader(options));
    if (res.data.status === STATUS_SUCCESS) {
      return res.data.data;
    }
    handleApiError(res.data.data || res.data, cleanedPath, handleInvalidToken);
    return new Promise(() => false);
  }

  public static async put(path: string, params?: { [key: string]: any }, handleInvalidToken = true) {
    const cleanedPath = path[0] === '/' ? path : `/${path}`;
    const res = await axios.put(cleanedPath, params || {}, injectAuthorizationHeader({}));
    if (res.data.status === STATUS_SUCCESS) {
      return res.data.data;
    }
    handleApiError(res.data.data || res.data, cleanedPath, handleInvalidToken);
    return new Promise(() => false);
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

function handleApiError(data: any, path: string, handleInvalidToken: boolean) {
  if (handleInvalidToken && data.type === API.INVALID_TOKEN) {
    Cookies.remove(`${APP_ID}.sessionToken`);
    Cookies.remove(`${APP_ID}.sessionApiDomain`);
    location.href = '/';
  } else {
    throw new ApiError(data);
  }
}

function injectAuthorizationHeader(options: { [key: string]: any }) {
  const sessionToken = Cookies.get(`${APP_ID}.sessionToken`);
  if (sessionToken) {
    options.headers = options.headers || {};
    options.headers.Authorization = `Bearer ${sessionToken}`;
  }
  return options;
}
