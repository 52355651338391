import ICommodityRecord from "@/interfaces/ICommodityRecord";
import Axios from 'axios';
import IProductRecord from "@/interfaces/IProductRecord";
import { config } from "@/main";
import ICommoditySummary from "@/interfaces/ICommoditySummary";

const axios = Axios.create({
  // crossDomain: true,
  responseType: 'json',
});

export default class Data {
  public static async fetchAllCalibers(): Promise<any[]> {
    const response = await Axios.get(`/${config.appId}/data/allCalibers.json`);
    return response.data;
  }

  public static async fetchAllGrades(): Promise<any[]> {
    const response = await Axios.get(`/${config.appId}/data/allGrades.json`);
    return response.data;
  }

  public static async fetchFeaturedCommodities(): Promise<ICommoditySummary[]> {
    const request = await axios.get(`/${config.appId}/data/featuredCommodities.json`);
    return request.data;
  }

  public static async fetchCommodity(id: string): Promise<ICommodityRecord> {
    const response = await Axios.get(`/${config.appId}/data/commodities/${id}.json`);
    return response.data;
  }

  public static async fetchProduct(id: string): Promise<IProductRecord> {
    const response = await Axios.get(`/${config.appId}/data/products/${id}.json`);
    return response.data;
  }

  public static async fetchProducts(): Promise<any> {
    const response = await Axios.get(`/${config.appId}/data/products.json`);
    return response.data;
  }
}
