
import * as Vue from 'vue';
import ContentWrapper from '@/layouts/ContentWrapper.vue';
import { config } from "@/main";

export default Vue.defineComponent({
  components: {
    ContentWrapper
  },
  setup() {

    return {
      legalOwner: config.legalOwner,
      footerSnacks: config.footerSnacks,
      marketingDomain: process.env.VUE_APP_MARKETING_DOMAIN,
    }
  }
});
