<template>
<div aria-live="assertive" class="fixed inset-0 flex items-end px-4 py-2 pointer-events-none sm:p-3 sm:items-start">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <transition v-for="alert of alerts" :appear="true" enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon class="h-8 w-8 text-green-400" aria-hidden="true" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-md font-medium text-gray-900">
                  {{alert.title}}
                </p>
                <p v-if="alert.extra" class="mt-1 text-sm text-gray-500">
                  {{alert.extra}}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button @click="show = false" class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import * as Vue from 'vue'
import { CheckCircleIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";

export default Vue.defineComponent({
  components: {
    CheckCircleIcon,
    XMarkIcon,
  },
  setup() {
    const alerts = Vue.reactive([]);

    return {
      alerts,
    }
  },
  mounted() {
    const emitter = useEmitter();
    emitter.on('addAlert', (alert) => this.addAlert({ ...alert }));
    // setTimeout(() => this.addAlert('Offer Recorded', '9mm Hunting-Grade'), 3e3);
    // setTimeout(() => this.addAlert('Rounds Bought!', '9mm Hunting-Grade'), 4e3);
    // setTimeout(() => this.addAlert('Rounds Sold!', '9mm Hunting-Grade'), 5e3);
  },
  methods: {
    removeAlert(alert) {

    },
    addAlert(alert) {
      this.alerts.push(alert);
      setTimeout(() => {
        const index = this.alerts.indexOf(alert);
        if (index >= 0) this.alerts.splice(index, 1);
      }, 5e3);
    }
  }
});
</script>
