import * as Vue from 'vue';
import { millicents } from "@contrarian/ui-shared/lib/Utils";
import IMarketSummary from '@/interfaces/IMarketSummary';

export function computedBuyPrice(market: IMarketSummary): Vue.Ref<string | number> {
  return Vue.computed(() => {
    if (market.buyPrice === 0) {
      return '--.-';
    } else {
      return millicents(market.buyPrice / 10);
    }
  })
}

export function computedSellPrice(market: IMarketSummary): Vue.Ref<string | number> {
  return Vue.computed(() => {
    if (market.sellPrice === 0) {
      return '--.-';
    } else {
      return millicents(market.sellPrice / 10);
    }
  })
}

export function computedSellPriceDiff(market: IMarketSummary): Vue.Ref<string | number> {
  return Vue.computed<string | number>(() => {
    if (market.sellPrice === 0) {
      return '-';
    } else {
      return Math.round((market.sellPrice - market.sellPriceStart) / 10);
    }
  })
}

export function computedPriceDiffColor(priceDiff: Vue.Ref<string | number>): Vue.Ref<string> {
  return Vue.computed<string>(() => {
    if (typeof priceDiff.value === 'string') return 'silver';
    if (priceDiff.value < 0) return 'red';
    if (priceDiff.value > 0) return 'green';
    return 'silver';
  });
}