
import * as Vue from 'vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/24/outline'
import { ExclamationTriangleIcon, GiftIcon, PlayIcon } from '@heroicons/vue/20/solid'
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import inviteModule from "../modules/inviteModule";
import { useStream } from '../lib/Stream'

const platformDomain = process.env.VUE_APP_PLATFORM_DOMAIN || '';

export default Vue.defineComponent({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    GiftIcon,
    PlayIcon,
    ExclamationTriangleIcon,
  },
  setup() {
    const store = useStore();
    const emitter = useEmitter();
    const router = useRouter();
    const stream = useStream();
    const isOpen = Vue.ref(false)
    const forceOpen = Vue.ref(false)
    const formError = Vue.ref('');

    const email = Vue.ref('')
    const firstName = Vue.ref('')
    const lastName = Vue.ref('')

    const { invite, inviteCountdown } = inviteModule(store);


    //check if we have any info on invite
    if (invite) {
      if(invite.email) email.value = invite.email;
      if(invite.firstName) firstName.value = invite.firstName;
      if(invite.lastName) lastName.value = invite.lastName
    }

    emitter.on('openRegister', (options = { forceOpen: false }) => {
      if (options.forceOpen) {
        forceOpen.value = true;
      }
      isOpen.value = true
    });

    emitter.on('closeRegister', () => {
      isOpen.value = false;
    });

    const model = {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      password: '',
    };

    return {
      emitter,
      stream,
      model,
      router,
      store,
      isOpen,
      forceOpen,
      invite,
      inviteCountdown,
      formError,
      isSubmitting: Vue.ref(false),
    }
  },
  methods: {
    closeOverlay() {
      if (this.forceOpen) return;
      this.isOpen = false;
    },
    async createAccount(evt: any) {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      this.formError = '';

        if (this.invite) {
                this.stream.createInviteEvent(this.invite.id, "Pressed Create Account - Waiting for response")
        }

      try {
        const data:any = {
          ...this.model,
        }
        if (this.invite) {
          data.inviteId = this.invite?.id
        }
        await this.store.dispatch('session/CreateAccount', data);
      } catch (error: any) {
        this.isSubmitting = false;
        this.formError = error.title;
        window.scrollTo(0, 0);
        if (this.invite) {
          this.stream.createInviteEvent(this.invite.id, "Create Account Failed - " + error.title)
        }
        return;
      }
      this.isSubmitting = false;
      this.isOpen = false;
      
      window.scrollTo(0, 0);
      setTimeout(() => {
        if(this.invite) {
          this.stream.createInviteEvent(this.invite.id, "Create Account Success - Sending To Platform")
        }
        window.location.href = platformDomain ? `//${platformDomain}/welcome` : '/welcome';
      }, 100);
    },
    openLogin() {
      this.isOpen = false;
      setTimeout(() => this.emitter.emit('openLogin'), 500);
    },
    openWaitlist() {
      this.isOpen = false;
      setTimeout(() => this.emitter.emit('openWaitlist'), 500);
    },
  }
});
