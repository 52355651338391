<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="isOpen = false">
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg max-w-sm w-screen text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-4xl sm:w-screen">
            <HowItWorksVideo />
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import * as Vue from 'vue';
import {useStore} from "vuex";
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import HowItWorksVideo from '@/components/HowItWorksVideo.vue';

export default Vue.defineComponent({
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    HowItWorksVideo,
  },
  setup(props) {
    const emitter = useEmitter();
    const store = useStore();
    const isOpen = Vue.ref(false);

    emitter.on('openHowItWorksVideo', () => isOpen.value = true );

    return {
      store,
      isOpen,
    }
  },
  methods: {  }
});
</script>

