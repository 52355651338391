import API, { API_DOMAIN } from '@contrarian/ui-shared/lib/API';
import { explodeValidSessionToken } from '@contrarian/ui-shared/lib/Utils';
import Cookies from '@contrarian/ui-shared/lib/Cookies';

const APP_ID = process.env.VUE_APP_ID;
const sessionToken = Cookies.get(`${APP_ID}.sessionToken`);
const payload = explodeValidSessionToken(sessionToken);
export const inviteRaw = localStorage.getItem('invite');
const siteVisitedWithCodeStorage = localStorage.getItem('siteVisitedWithCode');

export default {
  namespaced: true,
  state: {
    id: payload?.sessionId || null,
    adminId: payload?.adminId || null,
    memberId: payload?.memberId || null,
    sessionToken: sessionToken,
    invite: inviteRaw ? JSON.parse(inviteRaw) : null,
    wasLoggedIn: false,
    siteVisitedWithCode: siteVisitedWithCodeStorage ? siteVisitedWithCodeStorage : false,
    cart: [],
  },
  mutations: {
    setSessionToken(state: any, sessionToken: string) {
      Cookies.set(`${APP_ID}.sessionToken`, sessionToken);
      Cookies.set(`${APP_ID}.sessionApiDomain`, API_DOMAIN);
      const { sessionId, adminId, memberId } = explodeValidSessionToken(sessionToken) || {};
      const wasLoggedIn = !!memberId || state.wasLoggedIn;
      Object.assign(state, { sessionId, adminId, memberId, wasLoggedIn, sessionToken });
    },
    eraseAll(state: any) {
      Object.keys(state).forEach(key => {
        if (key !== 'wasLoggedIn') {
          state[key] = null;
        }
      });
      Cookies.remove(`${APP_ID}.sessionToken`);
      localStorage.removeItem('invite');
    },
    setInvite(state: any, invite: any) {
      state.invite = invite;
      localStorage.setItem('invite', JSON.stringify(invite));
    },
    clearInvite(state: any) {
      state.invite = null;
      localStorage.removeItem('invite');
    },
    setSiteVisitedWithCode(state: any, value: any) {
      state.siteVisitedWithCode = value;
      localStorage.setItem('siteVisitedWithCode', value);
    },
  },
  actions: {
    async LoginAdmin({ commit }: any, params: { email: string; password: string }) {
      const resData = await API.post('/authLogin', Object.assign({ type: 'admin' }, params));
      const { sessionToken } = resData;
      commit('setSessionToken', sessionToken);
      return resData;
    },

    async LoginMember({ commit }: any, params: { email: string; password: string }) {
      const resData = await API.post('/authLogin', Object.assign({ type: 'member' }, params));
      const { sessionToken } = resData;
      commit('setSessionToken', sessionToken);
      return resData;
    },

    async Logout({ state, commit }: any) {
      const resData = await API.post('/authLogout', { sessionToken: state.sessionToken });
      commit('eraseAll');
      return resData;
    },

    async CreateAccount({ commit }: any, data: any) {
      const resData = await API.post('/member', data, false);
      const { sessionToken } = resData;
      commit('setSessionToken', sessionToken);
      commit('clearInvite');
      return resData;
    },
  },
};
