import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { emitter, config } from "@/main";
import Index from "../views/Index.vue";
import store from '../store';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
   {
    path: "/commodities",
    name: "Commodities",
    component: () => import("../views/Commodities.vue"),
  }, {
    path: "/products",
    name: "Products",
    component: () => import("../views/Products.vue"),
  }, {
    path: "/commodities/:id",
    name: "Commodity",
    component: () => import("../views/Commodity.vue"),
  }, {
    path: "/products/:id",
    name: "Product",
    component: () => import("../views/Product.vue"),
  }, {
    path: "/account/verification",
    name: "AccountVerification",
    component: () => import("../views/account/Verification.vue"),
  }, {
    path: "/account",
    name: "AccountOverview",
    component: () => import("../views/account/Index.vue"),
  }, {
    path: "/account/asset-management",
    name: "AccountAssetManagement",
    component: () => import("../views/account/AssetManagement.vue"),
  }, {
    path: "/account/cash-balance",
    name: "AccountCashBalance",
    component: () => import("../views/account/CashBalance.vue"),
  }, {
    path: "/account/pending-trades",
    name: "AccountPendingTrades",
    component: () => import("../views/account/PendingTrades.vue"),
  },{
    path: "/account/profile-settings",
    name: "ProfileSettings",
    component: () => import("../views/account/ProfileSettings.vue"),
  }, {
    path: "/welcome",
    name: "Welcome",
    component: () => import(`../views/welcome/WelcomeUSAE.vue`),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const skipRegistration: string[] = []
const welcomeNavigation = ['/welcome', '/free-box-of-ammo'];

router.beforeEach((to, from, next) => {
  // @ts-ignore
  const { memberId, member, wasLoggedIn } = store.state.account;
  const isApproved = member.isApprovedTrader || member.isApprovedWholesaler;
  if (!memberId && config.forceRegistration && !skipRegistration.includes(to.path)) {
    const isDirectLoad = !from.matched.length;
    // after launch we should default to openRegister, but for now openLogin
    emitter.emit(wasLoggedIn ? 'openLogin' : 'openLogin', { forceOpen: isDirectLoad });
    next(false);
  } else if (memberId && !isApproved && !welcomeNavigation.includes(to.path)) {
    if (!welcomeNavigation.includes(from.path)) {
      next('/welcome');
    } else {
      next(false);
    }
  }
   else {
    emitter.emit('closeRegister');
    next();
  }
});

export default router;
