import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-full flex items-center text-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PopoverPanel = _resolveComponent("PopoverPanel")!
  const _component_Popover = _resolveComponent("Popover")!

  return (_openBlock(), _createBlock(_component_Popover, { class: "relative" }, {
    default: _withCtx(({ open }) => [
      _createVNode(_component_PopoverButton, {
        class: _normalizeClass([open ? '' : '', "inline-flex items-center text-base text-gray-500 px-3 py-2 text-sm font-medium group hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"])
      }, {
        default: _withCtx(() => [
          _createElementVNode("button", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass([open ? 'bg-indigo-100' : '',' flex items-center justify-center p-2 rounded'])
            }, [
              _createElementVNode("h1", {
                class: _normalizeClass(open ? 'text-indigo-500' : '')
              }, "Markets", 2),
              _createVNode(_component_ChevronDownIcon, {
                class: _normalizeClass([open ? 'text-indigo-500' : '', "inline-block w-5 h-5 ml-2 transition duration-150 ease-in-out"]),
                "aria-hidden": "true"
              }, null, 8, ["class"])
            ], 2)
          ])
        ]),
        _: 2
      }, 1032, ["class"]),
      _createVNode(_Transition, {
        "enter-active-class": "transition duration-200 ease-out",
        "enter-from-class": "translate-y-1 opacity-0",
        "enter-to-class": "translate-y-0 opacity-100",
        "leave-active-class": "transition duration-150 ease-in",
        "leave-from-class": "translate-y-0 opacity-100",
        "leave-to-class": "translate-y-1 opacity-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PopoverPanel, {
            class: "z-40 origin-top-right absolute right-0 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-slate-400 focus:outline-none",
            role: "menu"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: "/",
                class: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100",
                role: "menuitem"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Dashboard")
                ]),
                _: 1
              }),
              _createVNode(_component_router_link, {
                to: "/commodities",
                class: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 border-t",
                role: "menuitem"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Commodities")
                ]),
                _: 1
              }),
              _createVNode(_component_router_link, {
                to: "/products",
                class: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100",
                role: "menuitem"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Products")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}