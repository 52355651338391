export default class Cookies {
  public static set(name: string, rawValue: string, options: any = {}) {
    let value = `${encodeURIComponent(rawValue)}; path=/`;
    const expDate = new Date();
    
    options.exdays = options.exdays || 0;
    options.domain = 'domain' in options ? options.domain : getDomain();

    expDate.setDate(expDate.getDate() + options.exdays);
    
    if (options.domain) {
      value += `; domain=${options.domain}`;
    }
    
    if (options.exdays) {
      value += `; expires=${expDate.toUTCString()}`;
    }
    
    document.cookie = `${name}=${value}`;
  }

  public static get(name: string): string | void {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const x = cookie.substr(0, cookie.indexOf('=')).replace(/^\s+|\s+$/g, '');
      const y = cookie.substr(cookie.indexOf('=') + 1);
      if (x === name) {
        return decodeURIComponent(y);
      }
    }
  }

  public static remove(name: string, options: any = {}): void {
    if (this.get(name) === undefined) return;
    
    options.domain = 'domain' in options ? options.domain : getDomain();

    let value = '; path=/';
    if (options.domain) {
      value += `; domain=${options.domain}`;
    }
    value += '; expires=Thu, 01 Jan 1970 00:00:01 GMT';
    document.cookie = `${name}=${value}`;
  }
}

function getDomain(): string {
  const domain = window.location.host.split(':')[0];
  if (domain === 'localhost') return domain;

  const parts = domain.split('.');
  return ['', ...parts.slice(parts.length-2)].join('.');
}