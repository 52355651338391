
import * as Vue from 'vue';
import {
  Popover,
  PopoverButton,
  PopoverPanel
} from '@headlessui/vue';
import { ChevronDownIcon, CogIcon } from '@heroicons/vue/20/solid';
import {
  useStore
} from "vuex";
import {
  useRouter
} from "vue-router";

export default Vue.defineComponent({
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronDownIcon,
    CogIcon
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    return {
      store,
      router
    };
  },
  methods: {
   
  }
});
