import { createApp } from 'vue';
import mitt from 'mitt';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import InlineSvg from 'vue-inline-svg';
import BasicLayout from './layouts/BasicLayout.vue';
import ContentWrapper from './layouts/ContentWrapper.vue';
import App from './App.vue';
import store from './store';
import router from './router';
import usaeConfig from '../config.usae.json';
import './index.css';
import './vue-slider-component.css';
Bugsnag.start({
  apiKey: '9431a7b736801bb728d6689574edfce9',
  plugins: [new BugsnagPluginVue()]
})

const bugsnagVue = Bugsnag.getPlugin('vue');
export const config = usaeConfig;
export const emitter = mitt();

export const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.appId = config.appId;
app.config.globalProperties.appType = 'platform';
app.use(store);
app.use(router);
// @ts-ignore
app.use(bugsnagVue);
app.component('BasicLayout', BasicLayout);
app.component('ContentWrapper', ContentWrapper);
app.component('InlineSvg', InlineSvg);
app.mount('#app');