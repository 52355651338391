import { useStore, Store } from 'vuex';
import { io, Socket } from "socket.io-client";
import { API_DOMAIN } from "@contrarian/ui-shared/lib/API";

let socket: Socket;
const WS_PROTOCOL = process.env.VUE_APP_WS_PROTOCOL;

export function useStream() {
  const store = useStore();

  if (!socket) {
    const sessionToken = store.state.session.sessionToken;
    createSocket(sessionToken);
  }

  return new Stream(socket);
}

export default class Stream {
  readonly #socket: Socket;

  constructor(socket: Socket) {
    this.#socket = socket;
  }

  protected get socket(): Socket {
    return this.#socket as Socket;
  }

  public createInviteEvent(inviteId: string, text: string) {
    if (!inviteId) return;
    this.socket.emit(`InPersonEvent`, { inviteId, text });
  }
}

export function createSocket(sessionToken: string) {
  socket ??= io(`${WS_PROTOCOL}://${API_DOMAIN}?sessionToken=${sessionToken || ''}`, {
    path: '/live',
  });

  return socket;
}