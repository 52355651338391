
import * as Vue from 'vue';
import { config } from "@/main";
import Data from '@/lib/Data';
import { Float, FloatArrow } from '@headlessui-float/vue';
import { Popover, PopoverPanel, PopoverButton } from '@headlessui/vue';
import { ArrowSmallDownIcon, ArrowSmallUpIcon } from "@heroicons/vue/20/solid";
import API from '@contrarian/ui-shared/lib/API';
import { addCommas } from "@contrarian/ui-shared/lib/Utils";
import { computedSellPrice, computedSellPriceDiff, computedPriceDiffColor } from '@/lib/PriceUtils';
import IMarketSummary from '@/interfaces/IMarketSummary';

const closingByTimeout: { [timeout: number]: Vue.Ref<boolean> } = {};

export default Vue.defineComponent({
  components: {
    Float, 
    FloatArrow,
    Popover, 
    PopoverPanel,
    PopoverButton,
    ArrowSmallDownIcon,
    ArrowSmallUpIcon,
  },
  props: ['productId'],
  async setup(props: any, context: any) {
    const product = await Data.fetchProduct(props.productId);
    const priceSummary = Vue.reactive({
      buyPrice: 0,
      buyPriceStart: 0,
      sellPrice: 0,
      sellPriceStart: 0,
    }) as IMarketSummary;

    const price = computedSellPrice(priceSummary);
    const priceDiff = computedSellPriceDiff(priceSummary);
    const priceDiffColor = computedPriceDiffColor(priceDiff);
    const roundsTradedToday = Vue.ref(0);

    const shouldShow = Vue.ref(false);
    let timerTimeout: number | undefined;

    const open = () => {
      API.get(`/products/${product.id}/price`).then(x => {
        priceSummary.sellPrice = x.sellPrice;
        priceSummary.sellPriceStart = x.sellPriceStart;
        roundsTradedToday.value = x.roundsTradedToday;
      });

      const closingTimeouts = Object.keys(closingByTimeout);
      if (closingTimeouts.length) {
        closingTimeouts.forEach(timeout => {
          clearTimeout(Number(timeout));
          closingByTimeout[timeout as any].value = false;
          delete closingByTimeout[timeout as any];
        });
        timerTimeout = undefined;
      }
      shouldShow.value = true
    }

    const close = () => {
      shouldShow.value = false
    }

    const delayClose = () => {
      if (timerTimeout) {
        clearTimeout(timerTimeout);
        delete closingByTimeout[timerTimeout as any];
      }
      timerTimeout = setTimeout(() => shouldShow.value = false, 150) as unknown as number;
      closingByTimeout[timerTimeout] = shouldShow;
    }

    const { brand, caliber, bulletType, bulletWeight, caseMaterial } = product;
    const imageUrlSmall = `/${config.appId}/brand-icons/${product.brand.id}.svg`;

    return {
      shouldShow,
      open,
      close,
      delayClose,
      imageUrlSmall,
      product,
      brand, 
      caliber, 
      bulletType, 
      bulletWeight, 
      caseMaterial,
      price, 
      priceDiff,
      priceDiffColor,
      roundsTradedToday,
      class: context.attrs.class,
    };
  },
  methods: {
    addCommas,
  }
});

