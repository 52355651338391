import { createStore } from "vuex";
import { config } from '@/main';
import session from '@contrarian/ui-shared/store/session';
import account from './account';

const store = createStore({
  modules: {
    session,
    account,
  },
  state: {
  },
  mutations: {},
  actions: {},
});

store.dispatch('account/LoadMember');

export default store;