import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "BasicLayout" }
const _hoisted_2 = { style: {"min-height":"calc(100vh - 220px)"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavForMember = _resolveComponent("NavForMember")!
  const _component_NavForPublic = _resolveComponent("NavForPublic")!
  const _component_ContentWrapper = _resolveComponent("ContentWrapper")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.memberId)
      ? (_openBlock(), _createBlock(_component_NavForMember, {
          key: 0,
          disabledNav: _ctx.disabledNav
        }, null, 8, ["disabledNav"]))
      : (_openBlock(), _createBlock(_component_NavForPublic, { key: 1 })),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.ContentWrapper)
        ? (_openBlock(), _createBlock(_component_ContentWrapper, {
            key: 0,
            class: "mt-10"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }))
        : _renderSlot(_ctx.$slots, "default", { key: 1 })
    ]),
    _createVNode(_component_Footer)
  ]))
}