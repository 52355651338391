
  import * as Vue from 'vue';
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
  import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'

  export default Vue.defineComponent({
    components: {
      Bars3Icon,
      XMarkIcon,
      Disclosure, DisclosureButton, DisclosurePanel,
    },
    setup() {
      return {
        appId: process.env.VUE_APP_ID,
      }
    },

  });
