import moment from "moment";
import * as Vue from "vue";
import {useStore} from "vuex";

export default function inviteModule(store: any): { invite?: any, inviteCountdown?: Vue.Ref<IInviteCountdown> } {
  const invite = store.state.session.invite;
  if (!invite) return {};

  const inviteExpiresAtMoment = moment(invite.expiresAt);
  const inviteCountdown = Vue.ref(extractTimeRemaining(inviteExpiresAtMoment));

  const countdownInterval = setInterval(() => {
    inviteCountdown.value = extractTimeRemaining(inviteExpiresAtMoment);
    if (inviteCountdown.value.totalMillis < 0) {
      clearInterval(countdownInterval);
    }
  }, 1000);

  Vue.onBeforeUnmount(() => {
    clearInterval(countdownInterval);
  });

  return { invite, inviteCountdown };
}

function extractTimeRemaining(date: moment.Moment): IInviteCountdown {
  const duration = moment.duration(date.diff(moment()));
  const totalMillis = duration.milliseconds();
  const hours = duration.hours();
  duration.subtract(hours, 'hours');
  const minutes = duration.minutes();
  duration.subtract(minutes, 'minutes');
  const seconds = duration.seconds();
  duration.subtract(seconds, 'seconds');
  return { hours, minutes, seconds, totalMillis };
}

interface IInviteCountdown {
  hours: number;
  minutes: number;
  seconds: number;
  totalMillis: number;
}
