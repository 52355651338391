
import * as Vue from 'vue';
import {
  useStore
} from 'vuex';
import {
  useRouter
} from 'vue-router';
import ManageAccount from './ManageAccount.vue';
import MarketsDropdown from './MarketsDropdown.vue';

import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import {
  useStream
} from "@/lib/Stream";
import {
  config
} from "@/main";

function summarize(num: number): string {
  if (num > 999999) {
    return `${Math.round(num / 10000) / 10}m`;
  }
  if (num > 9999) {
    return `${Math.round(num / 100) / 10}k`;
  }
  if (num > 999) {
    return `${Math.round(num)}`;
  }
  return num.toString();
}

export default Vue.defineComponent({
  components: {
    ManageAccount,
    Bars3Icon,
    XMarkIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MarketsDropdown
  },
  props: ['disabledNav'],
  setup(props) {
    useStream();
    const emitter = useEmitter();
    const store = useStore();
    const router = useRouter();
    const member = Vue.reactive(store.state.account.member);
    const cashAvailable = Vue.ref(summarize(member.cashAvailable / 1000));
    const roundsOwned = Vue.ref(summarize(member.roundsOwned));
    const pendingRoundsToBuy = Vue.ref(summarize(member.pendingRoundsToBuy));
    const pendingRoundsToSell = Vue.ref(summarize(member.pendingRoundsToSell));
    const menuOpen = Vue.ref(false);
    const shippingCart = Vue.ref(store.state.account.shippingCart);

    const currencySymbol = config.currencySymbol;
    const appId = process.env.VUE_APP_ID;
    const searchIsOpen = Vue.ref(false);
    const isApproved = Vue.ref(member.isApprovedTrader || member.isApprovedWholesaler);

    Vue.watch(member, () => {
      isApproved.value = member.isApprovedTrader || member.isApprovedWholesaler;
      cashAvailable.value = summarize(member.cashAvailable / 1000);
      roundsOwned.value = summarize(member.roundsOwned);
      pendingRoundsToBuy.value = summarize(member.pendingRoundsToBuy);
      pendingRoundsToSell.value = summarize(member.pendingRoundsToSell);
    });
    
    let disabledNav = props.disabledNav


    return {
      emitter,
      store,
      router,
      appId,
      currencySymbol,
      cashAvailable,
      roundsOwned,
      pendingRoundsToBuy,
      pendingRoundsToSell,
      searchIsOpen,
      isApproved,
      menuOpen,
      disabledNav,
      shippingCart
    }
  },
  methods: {
    async logoutMobile() {
      // this.emitter.emit('addAlert', { title: 'You have been logged out.' });
      await this.store.dispatch('session/Logout');
      window.location.replace('/');
    },
    toggleSearch() {
      this.searchIsOpen = !this.searchIsOpen;
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    }
  }
});
