
import * as Vue from "vue";
import API from "@contrarian/ui-shared/lib/API";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";

import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import { useStore } from "vuex";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { InformationCircleIcon } from "@heroicons/vue/20/solid";
import { directive as VueInputAutowidth } from "vue-input-autowidth";
export default Vue.defineComponent({
  props: ["data"],
  emits: ["close"],
  directives: {
    autowidth: VueInputAutowidth,
  },
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    InformationCircleIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
  },
  setup(props: any) {
    const modalOpen = true;
    const emitter = useEmitter();
    const store = useStore();

    const commodityId = Vue.ref(props.data.commodityId);
    const productId = Vue.ref(props.data.productId);
    const roundsAvailable = Vue.ref(props.data.roundsAvailable);

    const price = props.data.price || 10;
    const priceIsLocked = Vue.ref(props.data.priceIsLocked || false);

    const skipConfirmation = Vue.ref(true);
    const milliCents = Vue.ref(0);
    const rounds = Vue.ref(20);

    skipConfirmation.value = true;
    milliCents.value = price / 10;
    rounds.value = 20;

    if (milliCents.value > 100) {
          milliCents.value = Number(milliCents.value.toFixed(0))
    }

    //Get Member's Inventory
    const inventoryHoldings = Vue.ref<any[]>([]);
    (async () => {
      const records = await API.get("/inventory");
      inventoryHoldings.value.push(...records);
    })();

    return {
      emitter,
      store,
      milliCents,
      priceIsLocked,
      rounds,
      skipConfirmation,
      commodityId,
      productId,
      price,
      inventoryHoldings,
      roundsAvailable
    };
  },
  methods: {
    selectProduct(product: any) {
      this.productId = product.productId;
    },
    async submitOrder() {
      if (this.rounds <= 0) return; 
      this.emitter.emit("addAlert", {
        title: "Sell Offer Submitted",
        extra: "You will be notified when processing is complete.",
      });
      const data = {
        price: this.milliCents * 10,
        rounds: Number(this.rounds),
        productId: this.productId,
        commodityId: this.commodityId,
      };
      await this.store.dispatch("account/SubmitSellOrder", data);
      this.$emit("close");
      this.emitter.emit("addAlert", {
        title: "Offer Recorded",
      });
    },
    decrCent() {
      this.milliCents = Number(this.milliCents) - 1;
    },
    incrCent() {
      this.milliCents = Number(this.milliCents) + 1;
    },
    decrBox() {
      this.rounds = Number(this.rounds) - 20;
      if (this.rounds <= 20) {
        this.rounds = 20;
      }
    },
    incrBox() {
      this.rounds = Number(this.rounds) + 20;
    },
  },
});
