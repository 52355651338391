
import * as Vue from 'vue';
import { useStore } from "vuex";
import { Float, FloatArrow } from '@headlessui-float/vue'
import { Popover, PopoverPanel, PopoverButton } from '@headlessui/vue'


export default Vue.defineComponent({
  components: {
    Float, 
    FloatArrow,
    Popover, 
    PopoverPanel,
    PopoverButton,
  },
  setup() {
    const show = Vue.ref(false);
    const timer = Vue.ref(null) as Vue.Ref<ReturnType<typeof setTimeout> | null>;

      const open = () => {
      if (timer.value !== null) {
        clearTimeout(timer.value)
        timer.value = null
      }
      show.value = true
    }

    const close = () => {
      show.value = false
    }

    const delayClose = () => {
      timer.value = setTimeout(() => {
        show.value = false
      }, 150)
    }

    return {
      show,
      open,
      close,
      delayClose,
    }
  }
});
