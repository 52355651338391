
import * as Vue from 'vue';
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Data from "@/lib/Data";
import { addCommas, millicents, currency } from "@contrarian/ui-shared/lib/Utils";
import BuyButton from "@/components/BuyButton.vue";
import SellButton from "@/components/SellButton.vue";
import ShipButton from "@/components/ShipButton.vue";
import { ArrowSmallDownIcon, ArrowSmallUpIcon } from "@heroicons/vue/20/solid";
import API from "@contrarian/ui-shared/lib/API";
import { computedSellPrice, computedSellPriceDiff, computedPriceDiffColor } from '@/lib/PriceUtils';

export default Vue.defineComponent({
  props: {
    id: String,
    record: Object,
    marketSummary: Object,
  },
  components: {
    SellButton,
    BuyButton,
    ShipButton,
    ArrowSmallUpIcon,
    ArrowSmallDownIcon,
  },
  async setup(props: any) {
    const store = useStore();
    const router = useRouter();
    const { id: commodityId, record, marketSummary } = props;
    const commodity = record || await Data.fetchCommodity(commodityId);
    const { caliber, grade, products } = commodity;

    const roundsOwned = Vue.ref(0)

    API.get('/inventorySummary', { commodityId: commodityId }).then(response => {
      if(response && response.rounds > 0) {
        roundsOwned.value = response.rounds
      }
    })

    const price = computedSellPrice(marketSummary);
    const priceDiff = computedSellPriceDiff(marketSummary);
    const priceDiffColor = computedPriceDiffColor(priceDiff);

    const lastOrderBought = Vue.computed(() => marketSummary.lastOrderBought);
    const lastOrderBoughtAt = Vue.ref();
    const lastOrderPrice = Vue.ref();

    return {
      appId: process.env.VUE_APP_ID,
      router,
      caliber,
      grade,
      products,
      price,
      priceDiff,
      priceDiffColor,
      marketSummary,
      commodityId,
      lastOrderBought,
      lastOrderBoughtAt,
      lastOrderPrice,
      updateLastOrderTimeout: 0,
      lastOrderVisible: Vue.ref(false),
      memberId: Vue.computed(() => store.state.account.memberId),
      alignTo: Vue.ref<any>({}),
      isHoveredOverBrand: Vue.ref(false),
      roundsOwned
    };
  },
  watch: {
    'lastOrderBought.createdAt': {
      immediate: true,
      handler() {
        if (!this.lastOrderBought?.createdAt) return;
        this.lastOrderVisible = false;
        if (this.updateLastOrderTimeout) clearTimeout(this.updateLastOrderTimeout);
        this.updateLastOrderTimeout = setTimeout(this.updateLastOrderPrice.bind(this), 1e3) as any;
      },
    }
  },
  mounted() {
    this.calculateAlignTo = this.calculateAlignTo.bind(this)
    this.calculateAlignTo();
    window.addEventListener('resize', this.calculateAlignTo);
  },
  beforeUnmount() {
    clearTimeout(this.updateLastOrderTimeout);
    window.removeEventListener('resize', this.calculateAlignTo);
  },
  methods: {
    addCommas,
    currency,
    updateLastOrderPrice() {
      this.lastOrderPrice = millicents(this.lastOrderBought.price / 10);
      this.lastOrderBoughtAt = this.lastOrderBought.createdAt.fromNow();
      this.lastOrderVisible = true;
      if (this.updateLastOrderTimeout) clearTimeout(this.updateLastOrderTimeout);

      if (this.lastOrderBoughtAt.includes('second')) {
        this.updateLastOrderTimeout = setTimeout(this.updateLastOrderPrice.bind(this), 1e3) as any;
      } else if (this.lastOrderBoughtAt.includes('minute')) {
        this.updateLastOrderTimeout = setTimeout(this.updateLastOrderPrice.bind(this), 10e3) as any;
      } else {
        this.updateLastOrderTimeout = setTimeout(this.updateLastOrderPrice.bind(this), 60e3) as any;
      }
    },
    calculateAlignTo() {
      const element = this.$el.querySelector('.button-group');
      const screenWidth = window.innerWidth;
      const { left: fromLeft, right, width } = this.$el.getBoundingClientRect();
      const fromRight = screenWidth - right;
      if (fromLeft - fromRight > width) {
        this.alignTo = { rightOf: element };
      } else if ( fromRight - fromLeft > width) {
        this.alignTo = { leftOf: element };
      } else {
        this.alignTo = { centerOf: element };
      }
    }
  }
});
