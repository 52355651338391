
import * as Vue from 'vue';
import { useStore } from 'vuex';
import { config } from '@/main';
import NavForPublic from "./nav/NavForPublic.vue";
import NavForMember from "./nav/NavForMember.vue";
import ContentWrapper from "./ContentWrapper.vue";
import Footer from "./Footer.vue";

export default Vue.defineComponent({
  components: {
    NavForPublic,
    NavForMember,
    ContentWrapper,
    Footer,
  },
  props: {
    ContentWrapper: Boolean,
    disabledNav: Boolean
  },
  setup(props) {
    const store = useStore();

    const enableShoppingCart = Vue.computed(() => config.enableShoppingCart);
    const memberId = Vue.computed(() => store.state.account.memberId);
    const disabledNav = props.disabledNav
   


    return { enableShoppingCart, memberId, disabledNav}
  }
});
