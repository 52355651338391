
import * as Vue from 'vue';
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import {
  useStore
} from "vuex";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import {
  InformationCircleIcon
} from '@heroicons/vue/20/solid';
import {
  directive as VueInputAutowidth
} from 'vue-input-autowidth';
import API from "@contrarian/ui-shared/lib/API";

export default Vue.defineComponent({
  props: ['data'],
  emits: ['close'],
  directives: {
    autowidth: VueInputAutowidth
  },
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    InformationCircleIcon,
  },
  setup(props: any) {
    const emitter = useEmitter();
    const store = useStore();

    const commodityId = Vue.ref(props.data.commodityId);
    const productId = Vue.ref(props.data.productId);

    const boxSizes = Vue.ref([])
    API.get('/warehouseInventory/' + (props.data.productId ? props.data.productId : props.data.commodityId)).then(response => {
      boxSizes.value = response
    })

    const price = props.data.price || 10;
    const priceIsLocked = Vue.ref(props.data.priceIsLocked || false);

    const skipConfirmation = Vue.ref(true);
    const milliCents = Vue.ref(0);
    const rounds = Vue.ref(20);

    skipConfirmation.value = true;
    milliCents.value = (price / 10);
    milliCents.value = Number(milliCents.value.toFixed(1))
    if (milliCents.value > 100) {
          milliCents.value = Number(milliCents.value.toFixed(0))
    }

    rounds.value = 20;

    return {
      error: Vue.ref(''),
      emitter,
      store,
      milliCents,
      priceIsLocked,
      rounds,
      skipConfirmation,
      commodityId,
      productId,
      boxSizes
    };
  },
  methods: {
    async submitOrder() {
      if (this.rounds <= 0) return; 
      const data = {
        price: Math.round(this.milliCents * 10),
        rounds: Number(this.rounds),
        productId: this.productId,
        commodityId: this.commodityId,
      };
      try {
        await this.store.dispatch('account/SubmitBuyOrder', data);
        this.$emit('close');
        this.emitter.emit('addAlert', {
          title: 'Offer Submitted',
          extra: 'You will be notified when processing is complete.',
        });
        this.emitter.emit('addAlert', {
          title: 'Offer Recorded'
        });
      } catch (error) {
        this.error = (error as Error).message;
      }
    },
    decrCent() {
      this.milliCents = Number(this.milliCents) - 1;
    },
    incrCent() {
      this.milliCents = Number(this.milliCents) + 1;
    },
    decrBox() {
      let nextDecrementFound = false
      let decrement = 0;
      while (!nextDecrementFound) {
        decrement--
        this.boxSizes.every((boxSize: any) => {
          if ((this.rounds + decrement) % boxSize.roundsPerBox == 0) {
            nextDecrementFound = true
            return;
          } else {
            return true
          }
        })
      }
      if(this.rounds + decrement >= 0) {
        this.rounds = Number(this.rounds + decrement);
      }
      else {
        this.rounds = 0
      }
    },
    incrBox() {
      let nextIncrementFound = false
      let increment = 0;
      while (!nextIncrementFound) {
        increment++
        this.boxSizes.every((boxSize: any) => {
          if ((this.rounds + increment) % boxSize.roundsPerBox == 0) {
            nextIncrementFound = true
            return;
          } else {
            return true
          }
        })
      }
      this.rounds = Number(this.rounds + increment);
    },
  }
});
