
import * as Vue from "vue";
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import { useStore } from "vuex";
import { PlusSmallIcon } from "@heroicons/vue/20/solid";
import { MinusSmallIcon } from "@heroicons/vue/20/solid";
import API from "@contrarian/ui-shared/lib/API";
import {
  directive as VueInputAutowidth
} from 'vue-input-autowidth';
export default Vue.defineComponent({
  props: ["data", "roundsAvailable"],
  emits: ["close"],
  components: {
    PlusSmallIcon,
    MinusSmallIcon,
  },
  directives: {
    autowidth: VueInputAutowidth
  },
  setup(props: any) {
    const modalOpen = true;
    const emitter = useEmitter();
    const store = useStore();

    const commodityId = Vue.ref(props.data.commodityId);
    const productId = Vue.ref(props.data.productId);
    const data = Vue.ref(props.data);
    const price = props.data.price || 100;
    const rounds = Vue.ref(20);

    const boxSizes = Vue.ref([])
    const minBoxSize = Vue.ref(20)
    API.get('/warehouseInventory/' + productId.value).then(response => {
      let boxes: any = []
      response.forEach((inventory: any) => {
        boxes.push(inventory.roundsPerBox)
      })
      boxes.sort((a: number, b: number) => {
        return a - b
      });
      minBoxSize.value = boxes[0] ? boxes[0] : 20
      boxSizes.value = boxes
    })

    const completeBoxOpen = Vue.ref(false)
    const milliCents = Vue.ref(0)
    milliCents.value = (price / 10);

    return {
      modalOpen,
      emitter,
      store,
      price,
      rounds,
      commodityId,
      productId,
      data,
      boxSizes,
      minBoxSize,
      completeBoxOpen,
      milliCents
    };
  },
  data() {
    return {
      boxesInput: 0,
    };
  },
  methods: {
    async submitOrder() {
      this.emitter.emit('addAlert', {
        title: 'Offer Submitted',
        extra: 'You will be notified when processing is complete.',
      });
      const data = {
        price: this.milliCents * 10,
        rounds: this.minBoxSize - this.data.roundsAvailable,
        productId: this.productId,
        commodityId: this.commodityId,
      };
      await this.store.dispatch('account/SubmitBuyOrder', data);
      this.$emit('close');
      this.emitter.emit('addAlert', {
        title: 'Offer Recorded'
      });
    },
    toggleCompleteBox() {
      this.completeBoxOpen = !this.completeBoxOpen
    },
    addItemToCart() {
      let {
        shippingCart
      } = this.store.state.account;
      shippingCart.forEach((item: any, index: number) => {
        console.log(item)
        if (item.productId == this.data.productId) {
          shippingCart.splice(index, 1)
        }
      })
      console.log(shippingCart)
      shippingCart.push({
        productId: this.data.productId,
        roundsAvailable: this.data.roundsAvailable,
        boxes: this.boxesInput / this.minBoxSize,
        rounds: Number(this.boxesInput),
        inventoryItemId: this.data.id,
      });
      console.log(shippingCart)

      this.store.state.account.shippingCart = shippingCart;
      this.$emit('close');
    },
    openShipping() {
      if(this.boxesInput <= 0) return;
      this.store.state.account.shippingCart = [{
        productId: this.data.productId,
        roundsAvailable: this.data.roundsAvailable,
        boxes: this.boxesInput / this.minBoxSize,
        rounds: Number(this.boxesInput),
        inventoryItemId: this.data.id,
      }, ];
      this.emitter.emit("openShip");
      this.$emit('close');
    },
    decrCent() {
      this.milliCents = Number(this.milliCents) - 1;
    },
    incrCent() {
      this.milliCents = Number(this.milliCents) + 1;
    },
    subtractRounds() {
      if (this.boxesInput > 0) {
        this.boxesInput -= this.minBoxSize;
      }
    },
    addRounds() {
      console.log()
      if (this.boxesInput < this.data.roundsAvailable && this.boxesInput + this.minBoxSize <= this.data.roundsAvailable) {
        this.boxesInput += this.minBoxSize;
      }
    },
  },
});
