
  import * as Vue from 'vue';
  import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
  import BuyPanel from "@/components/BuyPanel.vue";
  import SellPanel from "@/components/SellPanel.vue";
  import ShipPanel from '@/components/ShipPanel.vue';
  import { useStore } from "vuex";
  import { Popover, PopoverOverlay, PopoverPanel, TransitionChild, TransitionRoot} from "@headlessui/vue";

  export default Vue.defineComponent({
    components: {
      Popover,
      PopoverOverlay,
      PopoverPanel,
      TransitionChild,
      TransitionRoot,
      BuyPanel,
      SellPanel,
      ShipPanel
    },
    setup() {
      const modalOpen = true;
      const emitter = useEmitter();
      const store = useStore();

      const panelType = Vue.ref('');
      const panelData = Vue.ref({});
      const isOpen = Vue.ref(false);
      const bgBounds = Vue.reactive({
        left: '0px',
        top: '0px',
        width: '0px',
        height: '0px',
        borderRadius: '0px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        borderBottomLeftRadius: '0px',
        boxShadow: '0 0 0 99999px rgba(0, 0, 0, 0.08)',
      });

      const overlayBounds = Vue.reactive({
        top: '',
        bottom: '',
        left: '',
        right: '',
      });

      const innerBounds = Vue.reactive({
        top: '',
        bottom: '',
        left: '',
        right: '',
      });

      const triangleBounds = Vue.reactive({
        top: '',
        bottom: '',
        left: '',
        right: '',
      });

      emitter.on('openBuyPanel', ({ data, triggerElement, alignTo }: any) => {
        panelData.value = data;
        panelType.value = 'BuyPanel';
        isOpen.value = true;
        setBounds({ bgBounds, overlayBounds, innerBounds, triangleBounds }, { triggerElement, alignTo });
      });

      emitter.on('openSellPanel', ({ data, triggerElement, alignTo }: any) => {
        panelData.value = data;
        panelType.value = 'SellPanel';
        isOpen.value = true;
        setBounds({ bgBounds, overlayBounds, innerBounds, triangleBounds }, { triggerElement, alignTo });
      }); 

      emitter.on('openShipPanel', ({ data, triggerElement, alignTo }: any) => {
        panelData.value = data;
        panelType.value = 'ShipPanel';
        isOpen.value = true;
        setBounds({ bgBounds, overlayBounds, innerBounds, triangleBounds }, { triggerElement, alignTo });
      });

      return {
        isOpen,
        bgBounds,
        overlayBounds,
        innerBounds,
        triangleBounds,
        modalOpen,
        emitter,
        store,
        panelType,
        panelData,
      };
    },
    methods: {
      closeOverlay() {
        this.isOpen = false;
      }
    }
  });

  function setBounds({ bgBounds, overlayBounds, innerBounds, triangleBounds }: any, { triggerElement, alignTo }: any) {
    const style = window.getComputedStyle(triggerElement);
    const triggerRect = triggerElement.getBoundingClientRect();
    const triggerAbsoluteTop = triggerRect.top + window.window.scrollY;
    const triggerAbsoluteLeft = triggerRect.left + window.window.scrollX;

    bgBounds.left = `${triggerAbsoluteLeft}px`;
    bgBounds.top = `${triggerAbsoluteTop}px`;
    bgBounds.width = `${triggerRect.width}px`;
    bgBounds.height = `${triggerRect.height}px`;
    bgBounds.borderRadius = style.borderRadius;
    bgBounds.borderTopLeftRadius = style.borderTopLeftRadius;
    bgBounds.borderTopRightRadius = style.borderTopRightRadius;
    bgBounds.borderBottomRightRadius = style.borderBottomRightRadius;
    bgBounds.borderBottomLeftRadius = style.borderBottomLeftRadius;

    overlayBounds.top = `${triggerAbsoluteTop + triggerRect.height + 5}px`;
    overlayBounds.bottom = '';
    overlayBounds.left = '';
    overlayBounds.right = '';

    innerBounds.top = `0px`;
    innerBounds.bottom = '';
    innerBounds.left = '';
    innerBounds.right = '';

    triangleBounds.top = '-24px';
    triangleBounds.bottom = '';
    triangleBounds.left = '';
    triangleBounds.right = '';

    setXBounds({ bgBounds, overlayBounds, innerBounds, triangleBounds }, { triggerElement, triggerRect, alignTo });
  }

  function setXBounds({ bgBounds, overlayBounds, innerBounds, triangleBounds }: any, { triggerElement, triggerRect, alignTo }: any) {
    if (alignTo.rightOf) {
      const rightOf = alignTo.rightOf;
      const rightOfRect = rightOf.getBoundingClientRect();
      const triangleRight = (rightOfRect.right - triggerRect.right) + (triggerRect.width / 2) - 12.5;
      overlayBounds.right = `${window.innerWidth - rightOfRect.right}px`;
      innerBounds.right = '0px';
      triangleBounds.right = `${triangleRight}px`;

    } else if (alignTo.leftOf) {
      const leftOf = alignTo.leftOf;
      const leftOfRect = leftOf.getBoundingClientRect();
      const triangleLeft = (triggerRect.left - leftOfRect.left) + (triggerRect.width / 2) - 12.5;
      overlayBounds.left = `${leftOfRect.left}px`;
      innerBounds.left = '0px';
      triangleBounds.left = `${triangleLeft}px`;

    } else {
      const centerOf = alignTo.centerOf || triggerElement;
      const centerOfRect = centerOf.getBoundingClientRect();
      const triangleRight = (triggerRect.left - centerOfRect.left) + (triggerRect.width / 2) - 12.5;
      overlayBounds.left = `${centerOfRect.left}px`;
      innerBounds.left = '0px';
      triangleBounds.left = `${triangleRight}px`;
    }
  }
