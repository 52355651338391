<template>
  <div style="padding:56.21% 0 0 0;position:relative;">
    <iframe :src="`https://player.vimeo.com/video/${config.howItWorksVideoId}?h=845e4643e0&amp;badge=0&amp;autopause=0&autoplay=1&amp;player_id=0&amp;app_id=58479`" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="How It Works"></iframe>
  </div>
</template>

<script>
import * as Vue from 'vue';
import { config } from "@/main";

export default Vue.defineComponent({
  setup() {
    return {
      config
    }
  }
});
</script>
