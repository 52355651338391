import API from '@contrarian/ui-shared/lib/API';

const defaultMember = {
  id: '',
  firstName: '------',
  lastName: '------',
  email: '----@-------.---',
  birthdate: null,
  cashAvailable: 0,
  roundsOwned: 0,
  pendingRoundsToBuy: 0,
  pendingRoundsToSell: 0,
  isFoundingTrader: null,
  isApprovedTrader: false,
  isApprovedWholesaler: false,
};

const isLoaded = (function createPromisable() {
  const promiseLike: any = {};
  promiseLike.promise = new Promise((resolve, reject) => {
    promiseLike.resolve = resolve;
    promiseLike.reject = reject;
  });
  return promiseLike as { promise: Promise<void>, resolve: any, reject: any }
})();

export default {
  namespaced: true,
  state: {
    certifiesIn: 0,
    memberId: defaultMember.id,
    member: { ...defaultMember },
    shippingCart: [],
    isLoaded: isLoaded.promise,
  },
  mutations: {
    eraseAll(state: any) {
      Object.keys(state).forEach(key => {
        if (key === 'member') {
          eraseMember(state);
        }
      });
      localStorage.removeItem('member');
    },

    eraseMember(state: any) {
      eraseMember(state)
    },

    updateMember(state: any, member: any) {
      for (const [key, value] of Object.entries(member)) {
        state.member[key] = value;
      }
      localStorage.setItem('member', JSON.stringify(state.member));
    },

    updateMemberId(state: any, memberId: string | null) {
      state.memberId = memberId;
    },
  },
  getters: {
    member(state: any) {
      return state.member;
    }
  },
  actions: {
    async AddInventory({ state, commit }: any, data: any) {
      const resData = await API.post('/inventory', data, false);
      commit('updateMember', { roundsOwned: data.rounds });
      return resData;
    },

    async SubmitBuyOrder({ state, commit }: any, data: any) {
      data.certifiesIn = state.certifiesIn;
      data.memberId = state.memberId;
      await API.post('/buyOrder', data);
      commit('updateMember', { pendingRoundsToBuy: data.rounds });
    },
    async SubmitSellOrder({ state, commit }: any, data: any) {
      data.certifiesAt = state.certifiesIn;
      data.memberId = state.memberId;
      await API.post('/sellOrder', data);
      commit('updateMember', { pendingRoundsToSell: data.rounds });
    },

    async LoadMember({ state, rootState, commit }: any) {
      const { memberId } = rootState.session;
      if (!state.memberId) {
        commit('updateMemberId', memberId);
      }
      if (!memberId) {
        commit('eraseMember');
        return;
      }

      const rawMember = localStorage.getItem('member');
      if (rawMember) {
        const cachedMember = JSON.parse(rawMember);
        if (cachedMember.id == memberId) {
          commit('updateMember', cachedMember);
        } else {
          commit('eraseMember');
        }
      }
      const member = await API.get(`/member/${memberId}`);
      if (member) {
        commit('updateMember', member);
      } else {
        commit('eraseMember');
      }
      isLoaded.resolve();
    }
  },
};

// HELPERS //////

function eraseMember(state: any) {
  for (const k of Object.keys(state.member)) {
    if (k in defaultMember) {
      // @ts-ignore
      state.member[k] = defaultMember[k];
    } else {
      delete state.member;
    }
  }
}