import * as Vue from 'vue';
import JwtDecode from 'jwt-decode';
import ISessionTokenPayload from '@contrarian/interfaces/ISessionTokenPayload';

const ordinalIndicators: { [key: number]: string } = {
  1: 'st',
  2: 'nd',
  3: 'rd',
};

export function parseUrl(url: string) {
  const uriPartNames = [
    'source',
    'scheme',
    'authority',
    'host',
    'port',
    'path',
    'directory_path',
    'file_name',
    'query_string',
    'hash',
  ];
  const uriParts =
    new RegExp(
      '^(?:([^:/?#.]+):)?(?://)?(([^:/?#]*)(?::(\\d*))?)?((/(?:[^?#](?![^?#/]*\\.[^?#/.]+(?:[\\?#]|$)))*/?)?([^?#/]*))?(?:\\?([^#]*))?(?:#(.*))?',
    ).exec(url) || [];
  const uri: any = {};
  for (let i = 0, size = uriPartNames.length; i < size; i += 1) {
    uri[uriPartNames[i]] = uriParts[i] ? uriParts[i] : '';
  }
  uri.port = uri.port ? Number(uri.port) : 80;
  const domainParts = /^(.*?)\.?([^.]*\.\w+)$/.exec(uri.host);
  if (domainParts) {
    uri.sub_domain = domainParts[1];
    uri.top_level_domain = domainParts[2];
  } else {
    uri.sub_domain = uri.top_level_domain = '';
  }
  if (uri.directory_path.length > 0) {
    uri.directory_path = uri.directory_path.replace(/\/?$/, '/');
  }
  return uri;
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function isInt(n: any) {
  if (typeof n === 'string') return !n.includes('.');
  return n % 1 === 0;
}

export function addCommasToFloat(str: string) {
  const arr = str.split('.');
  const int = arr[0];
  const dec = arr.length > 1 ? `.${arr[1]}` : '';
  return (
    // tslint:disable-next-line:prefer-template
    int.replace(/(\d)(?=(\d{3})+$)/g, '$1,') +
    '.' +
    parseFloat(dec)
      .toFixed(2)
      .split('.')[1]
  );
}

export function addCommasToInt(str: string) {
  const arr = str.toString().split('.');
  const int = arr[0];
  return int.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
}

export function addCommas(num: string) {
  return isInt(num) ? addCommasToInt(num) : addCommasToFloat(num);
}

export function currency(num: string | number) {
  return addCommas(Number(num).toFixed(2));
}

export function millicents(rawNum: string | number) {
  let num = Number(rawNum);
  if (num >= 100) {
    num = Math.round(num);
    return num > 100 ? num : 100;
  }
  return num.toFixed(1);
}

export function ordinalIndicator(n: number) {
  return ordinalIndicators[n] || 'th';
}

export function addOrdinalIndicator(n: number) {
  return `${n}${ordinalIndicator(n)}`;
}

export function explodeValidSessionToken(sessionToken: string | void): ISessionTokenPayload | null {
  if (!sessionToken) return null;
  const decodedSessionToken = JwtDecode(sessionToken);
  if (decodedSessionToken === null) return null;
  const payload: ISessionTokenPayload = decodedSessionToken as ISessionTokenPayload;
  const currentTime = Date.now().valueOf() / 1000;
  return payload.exp < currentTime ? null : payload;
}

export function randomNumberBetween(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export function calculateMedian(arr: number[]) {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export function calculateAverage(arr: number[]) {
  return arr.reduce((t: number, x: any) => t + x, 0) / arr.length;
}
