<template>
<TransitionRoot as="template" :show="isOpen">
  <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="closeOverlay">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
        <div v-if="!showForgotPassword" class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
          <div class="pb-7">
            <h1 class="font-ammocorps text-center pt-3 text-3xl">SECURE MEMBER LOGIN</h1>
            <p class="text-center text-sm text-gray-500">( don't have a login? <a class="cursor-pointer text-indigo-600" @click="openRegister">Register</a> )</p>
          </div>

          <div v-if="formError" class="form-error rounded-md bg-red-50 p-4 mb-3">
            <div class="flex">
              <div class="flex-shrink-0">
                <ExclamationTriangleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">
                  {{formError}}
                </h3>
              </div>
            </div>
          </div>

          <form class="space-y-6 w-5/5 mx-auto" @submit.native.prevent="login">
            <div>
              <label for="email" class="block text-sm font-medium text-gray-400">
                Email address
              </label>
              <div class="mt-1">
                <input id="email" name="email" type="email" v-model="model.email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
              </div>
            </div>

            <div>
              <label for="password" class="block text-sm font-medium text-gray-400">
                Password
              </label>
              <div class="mt-1">
                <input id="password" name="password" type="password" v-model="model.password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
              </div>
            </div>

            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                <label for="remember-me" class="ml-2 block text-sm text-gray-400">
                  Remember me
                </label>
              </div>

              <div class="text-sm">
                <a @click="toggleForgotPassword" href="#" class="font-medium text-indigo-200 hover:text-indigo-300">
                  Forgot your password?
                </a>
              </div>
            </div>

            <div class="pt-3">
              <button type="submit" class="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-400 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Sign in
              </button>
            </div>
          </form>
        </div>
        <div v-else class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
          <div class="pb-7">
            <h1 class="font-ammocorps text-center pt-3 text-3xl">Forgot Password?</h1>
            <p v-if="isMarketingApp" class="text-center text-sm text-gray-500">( don't have a login? <a class="cursor-pointer text-indigo-600" @click="openRegister">Register</a> )</p>
          </div>

          <div v-if="errorMessage" class="form-error rounded-md bg-red-50 p-4 mb-3">
            <div class="flex">
              <div class="flex-shrink-0">
                <ExclamationTriangleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">
                  {{errorMessage}}
                </h3>
              </div>
            </div>
          </div>
          <div v-if="passwordResetStep == 1" class="w-12/12 mt-4">
            <div class="w-full">
              <p class="text-slate-500 text-center">Click 'Send Code' to send a verification code to your account email.</p>
              <div>
                <label for="email" class="block text-sm font-medium text-gray-400">
                  Email address
                </label>
                <div class="mt-1">
                  <input id="email" name="email" type="email" v-model="model.email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
              </div>
            </div>
            <button @click="sendVerificationCode" class="w-7/12 my-4 bg-gray-700 hover:bg-gray-500 text-white border px-5 py-2 rounded">Send Code</button>
            <button @click="toggleForgotPassword" class="w-5/12 my-4 text-red-500 px-5 py-2 rounded">Cancel</button>
          </div>
          <div v-if="passwordResetStep == 2" class="w-12/12 mt-4">
            <label>Verification Code</label>
            <div class="border border-black py-2 mt-2">
              <input v-model="tokenInput" class=" appearance-none bg-transparent border-none w-full text-sm text-gray-700 py-1 leading-tight focus:outline-none " type="text" placeholder="Code" aria-label="Code" />
            </div>
            <button @click="confirmVerificationCode" class="w-full my-4 bg-gray-700 hover:bg-gray-500 text-white border px-5 py-2 rounded">Verify</button>
          </div>
          <div v-if="passwordResetStep == 3" class="w-12/12 mt-4">
            <label>New Password</label>
            <div class="border border-black py-2 mb-2 mt-2">
              <input v-model="passwordInput" class=" appearance-none bg-transparent border-none w-full text-sm text-gray-700 py-1 leading-tight focus:outline-none " type="password" placeholder="new password" aria-label="Code" />
            </div>
            <label>Confirm New Password</label>
            <div class="border border-black py-2 mt-2">
              <input v-model="confirmPasswordInput" class=" appearance-none bg-transparent border-none w-full text-sm text-gray-700 py-1 leading-tight focus:outline-none " type="password" placeholder="confirm password" aria-label="Code" />
            </div>
            <button @click="submitPasswordReset" class="w-full my-4 bg-gray-700 hover:bg-gray-500 text-white border px-5 py-2 rounded">Verify</button>
          </div>
        </div>
      </TransitionChild>
    </div>
  </Dialog>
</TransitionRoot>
</template>

<script>
import * as Vue from 'vue';
import {
  useStore
} from "vuex";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import {
  CheckIcon
} from '@heroicons/vue/24/outline'
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import {
  ExclamationTriangleIcon
} from '@heroicons/vue/20/solid'
import {
  useRouter
} from "vue-router";
import API from "@contrarian/ui-shared/lib/API";

export default Vue.defineComponent({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    ExclamationTriangleIcon,
  },
  setup() {
    const emitter = useEmitter();
    const store = useStore();
    const router = useRouter();
    const internalInstance = Vue.getCurrentInstance();

    const appType = internalInstance.appContext.config.globalProperties.appType;
    const isMarketingApp = appType === 'marketing';

    const isOpen = Vue.ref(false);
    const forceOpen = Vue.ref(false)
    const formError = Vue.ref('');

    const showForgotPassword = Vue.ref(false)
    const tokenInput = Vue.ref('')
    const passwordInput = Vue.ref('')
    const confirmPasswordInput = Vue.ref('')
    const errorMessage = Vue.ref('')
    const passwordResetStep = Vue.ref(1)

    emitter.on('openLogin', (options = {
      forceOpen: false
    }) => {
      if (options.forceOpen) {
        forceOpen.value = true;
      }
      isOpen.value = true
    });

    const model = {
      email: '',
      password: '',
    };

    return {
      store,
      router,
      model,
      formError,
      isOpen,
      forceOpen,
      isMarketingApp,
      showForgotPassword,
      passwordResetStep,
      tokenInput,
      passwordInput,
      confirmPasswordInput,
      errorMessage
    }
  },
  methods: {
    sendVerificationCode() {
      API.post('/member/sendPasswordReset', { email: this.model.email })
      .then(response => {
        if (response.success) {
          // We know password reset email was sent
          this.passwordResetStep = 2
          this.errorMessage = ''
        } else {
          this.errorMessage = "Error sending password reset email";
        }
      }).catch(error => {
        if (error.message === 'Error Sending Password Reset') {
          this.errorMessage = "Error sending password reset email";
        } else {
          this.errorMessage = "Unknown server error";
        }
      });
    },
    confirmVerificationCode() {
      const data = {
        email: this.model.email,
        token: this.tokenInput
      }
      API.post('/member/confirmPasswordResetToken', data).then(response => {
        if (response.tokenCorrect == true) {
          //We know password reset email was sent
          this.passwordResetStep = 3
          this.errorMessage = ''
        } else {
          this.errorMessage = "incorrect code"
        }
      });
    },

    submitPasswordReset() {
      if (this.passwordInput == this.confirmPasswordInput) {
        const data = {
          email: this.model.email,
          token: this.tokenInput,
          password: this.passwordInput
        }
        API.post('/member/updatePasswordWithToken', data).then(response => {
          if (response.passwordReset == true) {
            this.passwordResetStep = 1
            this.toggleForgotPassword()
            this.errorMessage = ''
          }
        })
      } else {
        this.errorMessage = "Passwords don't match"
      }

    },
    toggleForgotPassword() {
      this.showForgotPassword = !this.showForgotPassword
    },
    async login() {
      this.formError = '';

      try {
        await this.store.dispatch('session/LoginMember', this.model);
      } catch (error) {
        return this.formError = error.title;
      }

      this.isOpen = false;

      const platformDomain = process.env.VUE_APP_PLATFORM_DOMAIN;
      if (platformDomain && location.href.includes(platformDomain)) {
        location.reload();
      } else {
        location.href = platformDomain ? `///${platformDomain}/` : '/';
      }
    },
    closeOverlay() {
      if (this.forceOpen) return;
      this.isOpen = false;
    },
    openRegister() {
      this.isOpen = false;
      setTimeout(() => this.emitter.emit('openRegister'), 500);
    },
  }
});
</script>
